import * as React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import Img from 'gatsby-image'

const Preview = styled(Link)`
  display: block;
  position: relative;
  background-color: #fff;
  border-radius: 4px;
  transition: box-shadow 0.4s cubic-bezier(0.645, 0.045, 0.355, 1),
    transform 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  box-shadow: 0 6px 18px 0 rgba(46, 50, 128, 0.15);

  & + a {
    margin-top: 1.5rem;
  }

  @media (min-width: 600px) {
    & + a {
      margin-top: 2rem;
    }
  }

  @media (min-width: 1180px) {
    & + a {
      margin-top: 2.5rem;
    }
  }

  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 8px 18px 0 rgba(46, 50, 128, 0.25);
  }
`

interface IFigureProps {
  image: string
}

const Figure = styled<IFigureProps, any>(Img)`
  width: 100%;
  height: 140px;
  border-radius: 4px 4px 0 0;
  background-size: cover;
  background-position: center;
  background-image: url(${props => props.image});

  @media (min-width: 600px) {
    height: 200px;
  }

  @media (min-width: 940px) {
    height: 260px;
  }

  @media (min-width: 1180px) {
    height: 300px;
  }

  @media (min-width: 1440px) {
    height: 340px;
  }
`

const Content = styled.main`
  padding: 1.5rem;

  @media (min-width: 1180px) {
    padding: 2rem;
  }
`

const Date = styled.span`
  text-transform: uppercase;
  display: block;
  color: #333;
  margin-bottom: 1rem;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0;
  font-size: 13px;

  @media (min-width: 940px) {
    font-size: 14px;
  }
`

const ContinueReading = styled.span`
  display: block;
  color: #5b63fe;
  margin-top: 1.5rem;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0;
  font-size: 13px;

  @media (min-width: 940px) {
    font-size: 14px;
  }

  @media (min-width: 600px) {
    margin-top: 2rem;
  }

  @media (min-width: 1180px) {
    margin-top: 2.5rem;
  }
`

interface IProps {
  data: {
    excerpt: string
    frontmatter: {
      date: string
      path: string
      title: string
      image: {
        childImageSharp: {
          fluid: object
        }
      }
    }
  }
}

export default ({ data }: IProps) => (
  <Preview to={`/blog/${data.frontmatter.path.replace('/', '')}`}>
    {data.frontmatter.image && (
      <Figure fluid={data.frontmatter.image.childImageSharp.fluid} />
    )}
    <Content>
      <h3>{data.frontmatter.title}</h3>
      <Date>{data.frontmatter.date}</Date>
      <p>{data.excerpt}</p>
      <ContinueReading>Continue reading</ContinueReading>
    </Content>
  </Preview>
)
