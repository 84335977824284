import * as React from 'react'
import Layout from '../components/Layout'
import Snippet from '../components/Snippet'
import Header from '../components/Header'
import styled from 'styled-components'
import { yPad, xPad } from '../utils/theme'
import Card from '../components/CTACard'
import { graphql, Link } from 'gatsby'
import { Button } from '../components/Button'
import { Helmet } from 'react-helmet'

const Column = styled.div`
  width: 100% !important;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  margin-bottom: 1rem;

  @media (min-width: 940px) {
    width: 66.66667% !important;
    padding-left: 1rem;
    padding-right: 1rem;
    margin-bottom: 2rem;
  }
`

const Sidebar = styled.div`
  display: block;
  width: 100%;

  @media (min-width: 940px) {
    width: 33.33333%;
  }

  /* sticky parent must be display: block */
  > div {
    position: sticky;
    top: 2rem;
  }
`

const Container = styled.div`
  width: 100%;
  position: relative;
  max-width: 1220px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;

  ${yPad};
  ${xPad};

  padding-top: 0 !important;
`

/* prettier-ignore */
interface IProps {
  data: {
    allMarkdownRemark: {
      edges: Array<{
        node: {
          excerpt: string;
          frontmatter: {
            date: string;
            path: string;
            title: string;
            image: {
              childImageSharp: {
                fluid: object;
              };
            };
          };
        };
      }>;
    };
  }
}

export default ({ data }: IProps) => (
  <Layout bg="#f0efeb">
    <Helmet>
      <title>
        Blog | Just Patios Just Patios - Patio, carport and room enclosure
        design ideas for Brisbane and the Sunshine Coast
      </title>
      <meta
        name="description"
        content="Need builders to custom design and install patios, carports and enclosures in Brisbane? Call the professional builders at Just Patios today!"
      />
    </Helmet>
    <Header style={{ paddingTop: '10rem', paddingBottom: '5rem' }}>
      <h1>
        Just Patios <i>Blog</i>
      </h1>
    </Header>
    <Container>
      <Column>
        {data.allMarkdownRemark.edges.map(({ node }) => (
          <Snippet data={node} />
        ))}
      </Column>
      <Sidebar>
        <div>
          <Card title="Contact Us">
            <p>
              Contact us today to get a free online estimate or on-site quote
              for your patio, carport, roof, or room enclosure.
            </p>
            <Link to="/contact-us/">
              <Button inverted={true} full={true}>
                Let's chat
              </Button>
            </Link>
          </Card>
          <Card title="Just Patios">
            <p>
              Just Patios are your reliable patio suppliers in Brisbane and the
              Sunshine Coast. Need a builder to custom design and install a
              patio, carport or enclosure in Brisbane? Call the professional
              builders at Just Patios today!
            </p>
          </Card>
        </div>
      </Sidebar>
    </Container>
  </Layout>
)

export const pageQuery = graphql`
  query BlogQuery {
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          frontmatter {
            date(formatString: "DD MMMM, YYYY")
            title
            image {
              childImageSharp {
                fluid(maxWidth: 1000) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            path
          }
        }
      }
    }
  }
`
